import { api } from '@/store/helpers'
import actions from '@/store/helpers/actions'

/**
 * The module's dispatchable actions.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	...actions,

	/**
	 * Create the given user.
	 *
	 * @param {Function} options.dispatch
	 * @param {Function} options.rootGetters
	 * @param {Object} payload.user
	 * @return {Response}
	 */
	async create({ dispatch, rootGetters }, user) {
		const partner = rootGetters.partner.id

		const response = await api()
			.partners(partner)
			.users()
			.create(user)

		dispatch('add', response.get('data', {}))

		return response
	},

	/**
	 * Delete the given user.
	 *
	 * @param {Function} options.dispatch
	 * @param {Object} options.state
	 * @param {Function} options.rootGetters
	 * @param {Number} payload.userId
	 * @return {Response}
	 */
	async delete({ dispatch, rootGetters }, userId) {
		const partner = rootGetters.partner.id

		const response = await api()
			.partners(partner)
			.users()
			.delete(userId)

		await dispatch('remove', userId)

		return response
	},

	/**
	 * Find a user by id.
	 *
	 * @param {Function} options.dispatch
	 * @param {Object} options.rootGetters
	 * @param {Number} id
	 * @return {Response}
	 */
	async find({ dispatch, rootGetters }, id) {
		const partner = rootGetters.partner.id

		const response = await api()
			.users(partner)
			.find(id)

		const data = response.get('data', [])

		dispatch('add', data)

		return response
	},

	/**
	 * Fetch all partner's users.
	 *
	 * @param {Function} options.dispatch
	 * @param {Object} options.rootGetters
	 * @return {Response}
	 */
	async get({ dispatch, rootGetters }) {
		const partner = rootGetters.partner.id

		const response = await api()
			.partners(partner)
			.users()
			.get()

		const data = response.get('data', [])

		dispatch('add', data)

		return response
	},

	/**
	 * Update the given user.
	 *
	 * @param {Function} options.dispatch
	 * @param {Object} options.rootGetters
	 * @param {Object} user
	 * @return {Response}
	 */
	async update({ dispatch, rootGetters }, user) {
		const partner = rootGetters.partner.id

		const response = await api()
			.partners(partner)
			.users()
			.update(user)

		dispatch('add', response.get('data', {}))

		return response
	},
}
