import Service from './Service'

/**
 * PartnerUsers API service.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class PartnerUsers extends Service {
	/**
	 * Create a new Service instance.
	 *
	 * @param {Request} request
	 * @param {Number?} partner
	 */
	constructor(request, partner) {
		super(request)

		/**
		 * The active partner id to scope the requests by.
		 *
		 * @type {Number?}
		 */
		this.partner = partner
	}

	/**
	 * Create a new user.
	 *
	 * @param {Object} user
	 * @param {?Number} partner
	 * @return {Promise}
	 */
	create(user, partner = null) {
		partner = partner || this.partner

		return this.request.post(`v4/partners/${partner}/users`, user)
	}

	/**
	 * Delete the given user.
	 *
	 * @param {Number} id
	 * @param {?Number} partner
	 * @return {Promise}
	 */
	delete(id, partner = null) {
		partner = partner || this.partner

		return this.request.delete(`v4/partners/${partner}/users/${id}`)
	}

	/**
	 * Get all users by partner from the API.
	 *
	 * @param {Number?} partner
	 * @return {Promise}
	 */
	get(partner = null) {
		partner = partner || this.partner

		return this.request.get(`v4/partners/${partner}/users`)
	}

	/**
	 * Update a user by id.
	 *
	 * @param {Object} user
	 * @param {?Number} partner
	 * @return {Promise}
	 */
	update(user, partner = null) {
		partner = partner || this.partner

		return this.request.put(`v4/partners/${partner}/users/${user.id}`, user)
	}
}
