import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		component: () =>
			import(/* webpackChunkName: "auth" */ '../layouts/Auth.vue'),
		children: [
			{
				path: '',
				name: 'login',
				component: () =>
					import(
						/* webpackChunkName: "login" */ '../views/Auth/Login.vue'
					),
			},
			{
				name: 'password.forgot',
				path: 'password/forgot',
				component: () =>
					import(
						/* webpackChunkName: "forgot-password" */ '../views/Auth/ForgotPassword.vue'
					),
			},
			{
				name: 'password.reset',
				path: 'password/reset/:token',
				component: () =>
					import(
						/* webpackChunkName: "password-reset" */ '../views/Auth/ResetPassword.vue'
					),
			},
		],
	},
	{
		path: '/partners/:partner',
		component: () =>
			import(/* webpackChunkName: "omd" */ '../layouts/Manager.vue'),
		meta: { auth: true },
		children: [
			{
				path: '',
				component: () =>
					import(
						/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'
					),
				name: 'app.dashboard',
			},
			{
				path: 'calls',
				component: () =>
					import(
						/* webpackChunkName: "calls" */ '@/views/Calls/Calls.vue'
					),
				meta: {
					search: ['calls'],
					inbox: true,
				},
				children: [
					{
						path: '',
						component: () =>
							import(
								/* webpackChunkName: "calls-index" */ '@/views/Calls/Index.vue'
							),
						name: 'app.calls',
						meta: {
							inbox: true,
							search: ['calls'],
						},
					},
					{
						path: ':sid',
						component: () =>
							import(
								/* webpackChunkName: "calls-show" */ '@/views/Calls/Show.vue'
							),
						name: 'app.calls.show',
						meta: {
							inbox: true,
							search: ['calls'],
						},
					},
				],
			},
			{
				path: 'chat',
				component: () =>
					import(
						/* webpackChunkName: "chat" */ '../views/Chat/Index.vue'
					),
				name: 'app.chat',
				meta: {
					inbox: true,
					search: ['channels'],
				},
				children: [
					{
						path: ':id',
						component: () =>
							import(
								/* webpackChunkName: "chat-show" */ '@/views/Chat/Show.vue'
							),
						name: 'app.chat.show',
						meta: {
							inbox: true,
							search: ['channels'],
						},
					},
				],
			},
			{
				path: 'paging',
				component: () =>
					import(
						/* webpackChunkName: "pages" */ '../views/Pages/Pages.vue'
					),
				meta: {
					inbox: true,
					search: ['pages'],
				},
				children: [
					{
						path: '',
						component: () =>
							import(
								/* webpackChunkName: "pages-index" */ '@/views/Pages/Index.vue'
							),
						name: 'app.paging',
						meta: {
							inbox: true,
							search: ['pages'],
						},
					},
					{
						path: 'create',
						component: () =>
							import(
								/* webpackChunkName: "pages-create" */ '@/views/Pages/Create.vue'
							),
						name: 'app.paging.create',
						meta: {
							inbox: true,
							search: ['pages'],
						},
					},
					{
						path: ':sid',
						component: () =>
							import(
								/* webpackChunkName: "pages-show" */ '@/views/Pages/Show.vue'
							),
						name: 'app.paging.show',
						meta: {
							inbox: true,
							search: ['pages'],
						},
					},
				],
			},
			{
				path: 'schedules',
				component: () =>
					import(
						/* webpackChunkName: "schedules" */ '../views/Schedules/Schedule.vue'
					),
				meta: { inbox: true, narrow: true },
				children: [
					{
						path: '',
						component: () =>
							import(
								/* webpackChunkName: "schedules-index" */ '@/views/Schedules/Index.vue'
							),
						name: 'app.schedules',
						meta: { inbox: true, narrow: true },
					},
					{
						path: 'time-blocks',
						component: () =>
							import(
								/* webpackChunkName: "schedules-time-blocks" */ '@/views/Schedules/TimeBlocks.vue'
							),
						name: 'app.schedules.time-blocks',
					},
					{
						path: 'requests',
						component: () =>
							import(
								/* webpackChunkName: "schedules-requests" */ '@/views/Schedules/Requests.vue'
							),
						name: 'app.schedules.requests',
					},
					{
						path: ':id',
						component: () =>
							import(
								/* webpackChunkName: "schedules-show" */ '@/views/Schedules/Show.vue'
							),
						name: 'app.schedules.show',
						meta: { inbox: true, narrow: true },
					},
				],
			},
			{
				path: 'settings',
				component: () =>
					import(
						/* webpackChunkName: "settings" */ '../views/Settings/Index.vue'
					),
				name: 'app.settings',
				children: [],
			},
			{
				path: 'providers',
				component: () =>
					import(
						/* webpackChunkName: "providers" */ '../views/Providers/Providers.vue'
					),
				children: [
					{
						path: '',
						component: () =>
							import(
								/* webpackChunkName: "providers-index" */ '@/views/Providers/Index.vue'
							),
						name: 'app.providers',
						meta: {
							inbox: true,
						},
					},
					{
						path: ':id',
						component: () =>
							import(
								/* webpackChunkName: "providers-edit" */ '@/views/Providers/Edit.vue'
							),
						name: 'app.providers.edit',
						meta: {
							inbox: true,
						},
					},
				],
			},
			{
				path: 'users',
				component: () =>
					import(
						/* webpackChunkName: "users" */ '../views/Users/Users.vue'
					),
				children: [
					{
						path: '',
						component: () =>
							import(
								/* webpackChunkName: "users-index" */ '@/views/Users/Index.vue'
							),
						name: 'app.users',
					},
					{
						path: 'create',
						component: () =>
							import(
								/* webpackChunkName: "users-create" */ '@/views/Users/Create.vue'
							),
						name: 'app.users.create',
					},
					{
						path: ':id',
						component: () =>
							import(
								/* webpackChunkName: "users-show" */ '@/views/Users/Show.vue'
							),
						name: 'app.users.show',
					},
				],
			},
		],
	},
	// 404 / Catch-all route redirect
	{
		path: '*',
		redirect: { name: 'login' },
	},
]

const router = new VueRouter({
	routes,
	mode: 'history',
})

export default router
